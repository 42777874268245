<template>
  <div id="app">
    <!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <!--    <index></index>-->
    <!-- 路由出口 -->
    <!-- 路由匹配到的组件将渲染在这里 -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>

    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import index from '@/components/index/index.vue'
export default {
  name: "app",
  components: {},
};
</script>

<style>
@import url("https://cdn.bootcss.com/twitter-bootstrap/3.3.7/css/bootstrap.min.css");
@import url("https://cdn.bootcss.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import "./iconfont/iconfont.css";
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
p {
  margin: 0 !important;
}
.video-player .vjs-big-play-button {
  top: 44% !important;
  left: 48% !important;
  border-radius: 50% !important;
  height: 3em !important;
  border: none !important;
  line-height: 2.8em !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}
#app {
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>
