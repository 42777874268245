import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/router/routingGuard"; // 路由守卫
import animated from "animate.css"
import "vue2-animate/dist/vue2-animate.min.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/zh-CN"; // lang i18n
import VuevideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import iView from "iview";
import "iview/dist/styles/iview.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
// require styles
import "swiper/dist/css/swiper.css";

Vue.use(animated)
Vue.use(VueAwesomeSwiper /* { default global options } */);
Vue.use(iView);
Vue.use(VuevideoPlayer);
Vue.config.productionTip = false;
// set ElementUI lang to EN
Vue.use(ElementUI, { locale });
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
